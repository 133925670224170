.sobrenospagebackground {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: right;
    flex-direction: row-reverse;
    background-color: #0F2F62;
}

.sobrenospagebackground .sobrenospagebackground-desktop {
    width: 100%;
    padding-left: 100px;
    align-items: center;
    position: relative;
}

.sobrenospagebackground .sobrenospagebackground-desktop .catch {
    padding-left:  0px;
    padding-right: 0px;
    margin-top: 110px;
    margin-bottom: 10px;
    line-height: 35px;
    color: white;
    font-style: light;
    font-weight: 100;
    font-size: 21px;
    text-align: left;
}

.sobrenospagebackground .sobrenospagebackground-desktop .title {
    margin-top: 10px;
    color: white;
    font-style: bold;
    font-weight: 700;
    font-size: 40px;
    text-align: left;
    line-height: 35px;
}

.sobrenospagebackground .sobrenospagebackground-phone .catch {
    margin-top: 110px;
    margin-bottom: 10px;
    line-height: 35px;
    color: white;
    font-style: light;
    font-weight: 100;
    font-size: 21px;
    text-align: left;
}

.sobrenospagebackground .sobrenospagebackground-phone .title {
    margin-top: 10px;
    color: white;
    font-style: bold;
    font-weight: 700;
    font-size: 28px;
    text-align: left;
    line-height: 35px;
}

.sobrenospagebackground .sobrenospagebackground-phone {
    padding-left: 25px;
    display: none;
    width: 100%;
    height: auto;
}

@media (max-width: 1230px)
{
    .sobrenospagebackground .desktop {
        display: none;
    }
    .sobrenospagebackground .phone {
        display: flex;
    }

}