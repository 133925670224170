.App {
  width: 100%;
  max-width: 1580px;  
  /*border: 3px solid black;*/
  height: auto;
  display: flex; 
  flex-direction: column;
  text-align: center;
  margin:auto;
}

.font-face-comfortaaBold {
  font-family: "ComfortaaBold";
}

.font-face-comfortaa {
  font-family: "Comfortaa";
}

.font-face-comfortaaLight {
  font-family: "ComfortaaLight";
}

body {
  padding: 0;
  margin: 0;
}