@font-face {
    font-family: "Comfortaa";
    src: url('./fonts/comfortaa/Comfortaa-Regular.ttf');
    font-weight: 400;
}

@font-face {
    font-family: "ComfortaaBold";
    src: url('./fonts/comfortaa/Comfortaa-Bold.ttf');
    font-weight: bold;
}

@font-face {
    font-family: "ComfortaaLight";
    src: url('./fonts/comfortaa/Comfortaa-Light.ttf');
    font-weight: light;
}