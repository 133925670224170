.landpage {
    width: 100%;
    height: 700px;
    /*border: 3px solid blue;*/
    display: flex;
    background-color: transparent;
    align-items: right;
    flex-direction: row-reverse;
}

.landpage .landpage-left {
    width: calc(100% - 670px);
    align-items: center;
    background: transparent;
    position: relative;
    /*border: 3px solid greenyellow;*/
}

/*
.landpage .landpage-right {
    position: absolute;
    display: flex;
    border: 3px solid yellow;
    top: 0px; 
    right: calc(100% - 2000px);
    z-index: -10;
    width: 620px;
    height: auto;
}*/

.landpage .landpage-right {
    display: flex;
    /*border: 3px solid yellow;*/
    top: 100px; 
    z-index: -10;
    width: 720px;
    height: auto;
}

.landpage .landpage-right img {
    width: 670px; 
    height: auto;
    margin-right: 5px;
    position: absolute;
    top: 0px;
    /*border: 3px solid grey;*/
}

.landpage .content-group {
    width: 600px;
    height: 550px;
    padding: 50px;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    text-align: left;
}

.landpage .landpage-left .title {
    margin-top: 80px;
    background: transparent;
    color: #004098;
    font-style: bold;
    font-weight: 400;
    font-size: 20px;
    text-align: left;
}

.landpage .landpage-left .catch {
    padding-left:  0px;
    padding-right: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
    background: transparent;
    line-height: 45px;
    color: #3B4256;
    font-style: bold;
    font-weight: 400;
    font-size: 40px;
    text-align: left;
}

.landpage .landpage-left .content {
    margin-bottom: 50px;    
    background: transparent;
    color: #6F7482;
    font-style: bold;
    font-weight: 400;
    font-size: 20px;
    text-align: left;
}

.landpage .landpage-left .landpage-blue-button {
    border-radius: 6px;
    margin-top: 10px;
    text-align: center;
    font-size: 20px;
    cursor: pointer;
    border: 3px solid #004098;
    color: white;
    background-color: #004098;
    width: 250px;
    height: 60px;
    font-weight: 550;
}

.landpage .landpage-middle-phone {
    display: none;
    width: 100%;
    height: auto;
}

.landpage .content-group-phone {
    width: 94%;
    align-content: center;
    position: absolute;
    top: 200px;
    left: 0%;
    margin-left: 3%;
    margin-left: 3%;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 15px 50px 15px;
    background-color:  rgba(59, 66, 86, 0.8); 
}

.landpage .landpage-middle-phone .background {
    width: 100%;
    height: auto;
}

.landpage .landpage-middle-phone img {
    width: 100%; 
    height: auto;
}

.landpage .landpage-middle-phone .title {
    margin-top: 30px;
    color: #FFD006;
    font-size: 18px;
}

.landpage .landpage-middle-phone .catch {
    margin-top: 20px;
    color: white;
    font-size: 28px;
    font-weight: 900;
}

.landpage .landpage-middle-phone .content {
    margin-top: 30px;
    color: white;
    font-size: 15px;
}

.landpage .landpage-middle-phone .landpage-blue-button {
    border-radius: 6px;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
    font-size: 20px;
    cursor: pointer;
    border: 3px solid #004098;
    color: white;
    background-color: #004098;
    width: 250px;
    height: 60px;
    font-weight: 550;
}

@media (max-width: 1230px)
{
    .landpage .desktop {
        display: none;
    }
    .landpage .phone {
        display: flex;
    }

}