.navbar {
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    background-color: transparent;
}

.navbar .nav-left {
    width: 220px;
    height: 100px;
    display: flex;
    align-items: center;
    padding-left: 15px;
    /*border: 1px solid black;*/
}

.navbar .nav-left img {
    width: 200px;
}

.navbar .nav-middle {
    width: 330px;
    height: 100px;
    display: flex;
    align-items: center;
    font-style: bold;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: #6F7482;
    /*border: 1px solid black;*/
}

.navbar .nav-middle a {
    text-decoration: none;
    border-bottom: 3px solid white;
    padding-bottom: 3px;
    color: #6F7482;
    margin: 16px;
}

.navbar .nav-middle .active {
    color: #004098;
    border-bottom: 4px solid #FFD006;
    padding-bottom: 4px;
    border-radius: 1px;
    font-weight: 900;
}

.navbar .nav-middle a:active {
    color: #004098;
    border-bottom: 4px solid #FFD006;
    padding-bottom: 4px;
    border-radius: 1px;
    font-weight: 900;
}

.navbar .nav-middle a:hover {
    color: #004098;
    border-bottom: 4px solid #FFD006;
    padding-bottom: 4px;
    border-radius: 1px;
    font-weight: 900;
}

.navbar .nav-right {
    height: 100px;
    position: relative;
    margin-right: 65px;
    /*border: 1px solid black;*/
}

.nav-blue-button {
    border-radius: 6px;
    margin: 10px;
    text-align: center;
    font-size: 20px;
    cursor: pointer;
    border: 3px solid #004098;
    color: white;
    background-color: #004098;
    width: 250px;
    height: 60px;
    font-weight: 550;
}

.nav-white-button {
    border-radius: 6px;
    margin: 10px;
    text-align: center;
    font-size: 20px;
    cursor: pointer;
    border: 3px solid #004098;
    color: #004098;
    background-color: white;
    width: 300px;
    height: 60px;
    font-weight: 550;
}

.navbar .nav-toggle
{
    display: none;
    width: 80px;
    height: 100px;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    padding-right: 10px;
}

.navbar .nav-toggle .nav-bar {
    font-size: 35px;
    color: #004098;
    justify-content: center;
    padding-bottom: 10px;
}

.navbar .nav-toggle-on
{
    display: none;
    width: 80px;
    height: 100px;
    align-items: center;
    justify-content: center;
    padding-right: 10px;
    z-index: 100;
}

.navbar .nav-toggle-on .nav-bar {
    font-size: 35px;
    color: white;
    cursor: pointer;
    justify-content: center;
    padding-bottom: 10px;
    z-index: 100;
}

.navbar .nav-toggle-on .nav-middle {
    width: 100%;
    height: 1000px;
    position: absolute;
    display: inline-block;
    top: 0px;
    left: 0px;
    z-index: 99;
    font-size: 25px;
    color: white;
    text-align: left;
    background-color: #004098;
}

.navbar .nav-toggle-on .nav-middle a {
    color: white;
}

.navbar .nav-toggle-on .nav-middle .home-mobile
{
    margin-top: 50px;
    margin-left: 35px;
    margin-right: 35px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, .5);
}

.navbar .nav-toggle-on .nav-middle .sobre-nos-mobile
{
    margin-top: 15px;
    margin-left: 35px;
    margin-right: 35px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, .5);
}

.navbar .nav-toggle-on .nav-middle .contato-mobile
{
    margin-top: 15px;
    margin-left: 35px;
    margin-right: 35px;
    padding-bottom: 15px;
}

.navbar .nav-toggle-on .nav-middle .logo-mobile
{
    width: 250px;
    height: 100px;
    padding-top: 20px;
    padding-left: 15px;

}

.navbar .nav-toggle-on .nav-middle img
{
    width: 200px;
}

@media (max-width: 1230px)
{
    .navbar .desktop {
        display: none;
    }
    .navbar .phone {
        display: flex;
    }
}