.contatopage {
    width: 100%;
    height: 1070px;
    display: flex;
    align-items: right;
    flex-direction: row-reverse;
    background-color: #0F2F62;
}

.contatopage .contatopage-left {
    width: calc(100% - 670px);
    align-items: center;
    background: transparent;
    position: relative;
}

.contatopage .contatopage-right {
    display: flex;
    top: 100px; 
    width: 720px;
    height: auto;
    border-left: 1px solid white;
}

.contatopage .contatopage-right .form{
    display: flex;
    border: 3px solid white;
    background-color: white;
    border-radius: 10px;
    width: 470px;
    height: 577px;
    margin: auto;
    padding-top: 40px;
}

.contatopage .contatopage-right input {
    border: 2px solid #E9EBED;
    border-radius: 10px;
    width: 300px;
    height: 48px;
}

.contatopage .contatopage-right .formemail {
    width: 400px;
    margin-left: 35px;
}

.contatopage .contatopage-right .formname {
    width: 250px;
    margin-left: 35px;
}

.contatopage .contatopage-right .formsend {
    width: 118px;
    margin-left: 35px;
    color: white;
    font-weight: 700;
    background-color: #004098;
}

.contatopage .contatopage-right label {
    color: #1E0E62;
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 25px;
    margin-left: 35px;
}

.contatopage .contatopage-right textarea {
    border: 2px solid #E9EBED;
    border-radius: 10px;
    margin-left: 35px;
    width: 400px;
    height: 150px;
    max-height: 200px;
    min-height: 48px;
    
}

.contatopage .content-group {
    width: 600px;
    height: 550px;
    padding: 25px;
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    text-align: left;
}

.contatopage .contatopage-left .icons {
    color: white; 
    display: inline-block;
    padding-right: 10px;
    font-weight: 500;
    font-size: 23px;
    text-align: left;
}

.contatopage .contatopage-left .title {
    margin-top: 80px;
    background: transparent;
    color: white;
    font-style: bold;
    font-weight: 700;
    font-size: 40px;
    text-align: left;
    line-height: 45px;
}

.contatopage .contatopage-left .catch {
    padding-left:  0px;
    padding-right: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
    background: transparent;
    line-height: 50px;
    color: white;
    font-style: bold;
    font-weight: 500;
    font-size: 21px;
    text-align: left;
}

.contatopage .contatopage-left .phone {
    margin-top: 80px;    
    color: white;
    font-style: bold;
    font-weight: 500;
    font-size: 20px;
    text-align: left;
    line-height: 45px;
}


.contatopage .contatopage-left .email {
    margin-top: 40px;    
    color: white;
    font-style: bold;
    font-weight: 500;
    font-size: 20px;
    text-align: left;
    line-height: 45px;
}

.contatopage .contatopage-left .address {
    margin-top: 40px;    
    color: white;
    font-style: bold;
    font-weight: 500;
    font-size: 20px;
    text-align: left;
    line-height: 45px;
}

.contatopage .contatopage-middle-phone {
    display: none;
    width: 100%;
    height: auto;
}

.contatopage .contatopage-middle-phone .icons {
    color: white; 
    display: inline-block;
    padding-right: 10px;
    font-weight: 500;
    font-size: 23px;
    text-align: left;
}

.contatopage .contatopage-middle-phone .title {
    margin-top: 80px;
    background: transparent;
    color: white;
    font-style: bold;
    font-weight: 700;
    font-size: 30px;
    text-align: left;
    line-height: 45px;
}

.contatopage .contatopage-middle-phone .catch {
    padding-left:  0px;
    padding-right: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
    background: transparent;
    line-height: 40px;
    color: white;
    font-style: bold;
    font-weight: 500;
    font-size: 18px;
    text-align: left;
}

.contatopage .contatopage-middle-phone .phone {
    margin-top: 80px;    
    color: white;
    font-style: bold;
    font-weight: 500;
    font-size: 18px;
    text-align: left;
    line-height: 45px;
}


.contatopage .contatopage-middle-phone .email {
    margin-top: 40px;    
    color: white;
    font-style: bold;
    font-weight: 500;
    font-size: 18px;
    text-align: left;
    line-height: 45px;
}

.contatopage .contatopage-middle-phone .address {
    margin-top: 40px;    
    color: white;
    font-style: bold;
    font-weight: 500;
    font-size: 18px;
    text-align: left;
    line-height: 45px;
}

@media (max-width: 1230px)
{
    .contatopage .desktop {
        display: none;
    }
    .contatopage .phone {
        display: flex;
    }

}