.sobrenospagecontent {
    width: 100%;
    height: 1070px;
    display: flex;
    align-items: right;
    flex-direction: row-reverse;
    background-color: white;
}

.sobrenospagecontent .sobrenospagecontent-left {
    width: 50%;
    align-items: center;
    background: transparent;
    position: relative;
    margin: auto;
    margin-top: 0px;
    padding: 60px;
}

.sobrenospagecontent .sobrenospagecontent-left .bar {
    border: 2px solid #FFD006;
    border-radius: 10px;
    background-color: #FFD006;
    width: 50px;
}


.sobrenospagecontent .sobrenospagecontent-left .icons {
    color: #FFD006; 
    display: inline-block;
    padding-right: 10px;
    font-weight: 500;
    font-size: 23px;
    text-align: left;
}

.sobrenospagecontent .sobrenospagecontent-left .title {
    margin-top: 10px;
    margin-bottom: 40px;
    color: #3B4256;
    font-style: bold;
    font-weight: 700;
    font-size: 30px;
    text-align: left;
    line-height: 35px;
}

.sobrenospagecontent .sobrenospagecontent-left .catch {
    margin-top: 10px;
    color: #3B4256;
    font-style: bold;
    font-weight: 400;
    font-size: 17px;
    text-align: left;
    line-height: 35px;
}

.sobrenospagecontent .sobrenospagecontent-left .items {
    margin-top: 10px;
    color: #3B4256;
    font-style: bold;
    font-weight: 400;
    font-size: 17px;
    text-align: left;
    line-height: 35px;
}

.sobrenospagecontent .sobrenospagecontent-left .map {
    margin-top: 60px;
}

.sobrenospagecontent .sobrenospagecontent-right {
    display: flex;
    width: 50%;
    height: auto;
    margin: auto;
    margin-top: 0px;
    padding: 60px;
}

.sobrenospagecontent .sobrenospagecontent-right .items {
    margin-top: 10px;
    color: #3B4256;
    font-style: bold;
    font-weight: 400;
    font-size: 17px;
    text-align: left;
    line-height: 25px;
}

.sobrenospagecontent .sobrenospagecontent-right .catch {
    margin-top: 15px;
    color: #3B4256;
    font-style: bold;
    font-weight: 400;
    font-size: 17px;
    text-align: left;
    line-height: 25px;
}

.sobrenospagecontent .sobrenospagecontent-right .mission {
    margin-top: 10px;
    color: #3B4256;
    font-style: bold;
    font-weight: 400;
    font-size: 25px;
    text-align: left;
    line-height: 30px;
}

.sobrenospagecontent .sobrenospagecontent-right .bar {
    margin-top: 70px;
    border: 2px solid #FFD006;
    border-radius: 10px;
    background-color: #FFD006;
    width: 50px;
}

.sobrenospagecontent .sobrenospagecontent-right .title {
    margin-top: 10px;
    margin-bottom: 40px;
    color: #3B4256;
    font-style: bold;
    font-weight: 700;
    font-size: 30px;
    text-align: left;
    line-height: 35px;
}

.sobrenospagecontent .sobrenospagecontent-right .button {
    border-radius: 6px;
    margin-top: 50px;
    text-align: center;
    font-size: 20px;
    cursor: pointer;
    border: 3px solid #004098;
    color: white;
    background-color: #004098;
    width: 240px;
    height: 60px;
    font-weight: 550;
}

.sobrenospagecontent .sobrenospagecontent-phone {
    padding: 30px;
    display: none;
    width: 100%;
    height: auto;
}

.sobrenospagecontent .sobrenospagecontent-phone .bar {
    margin-top: 20px;
    border: 2px solid #FFD006;
    border-radius: 10px;
    background-color: #FFD006;
    width: 50px;
}

.sobrenospagecontent .sobrenospagecontent-phone .title {
    margin-top: 10px;
    margin-bottom: 25px;
    color: #3B4256;
    font-style: bold;
    font-weight: 700;
    font-size: 25px;
    text-align: left;
    line-height: 35px;
}

.sobrenospagecontent .sobrenospagecontent-phone .items {
    margin-top: 5px;
    color: #3B4256;
    font-style: bold;
    font-weight: 400;
    font-size: 15px;
    text-align: left;
    line-height: 35px;
}

.sobrenospagecontent .sobrenospagecontent-phone .catch {
    margin-top: 10px;
    color: #3B4256;
    font-style: bold;
    font-weight: 400;
    font-size: 15px;
    text-align: left;
    line-height: 30px;
}

.sobrenospagecontent .sobrenospagecontent-phone .icons {
    color: #FFD006; 
    display: inline-block;
    padding-right: 10px;
    font-weight: 500;
    font-size: 23px;
    text-align: left;
}

.sobrenospagecontent .sobrenospagecontent-phone .mission {
    margin-top: 10px;
    color: #3B4256;
    font-style: bold;
    font-weight: 400;
    font-size: 25px;
    text-align: left;
    line-height: 30px;
}

.sobrenospagecontent .sobrenospagecontent-phone .button {
    border-radius: 6px;
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
    font-size: 20px;
    cursor: pointer;
    border: 3px solid #004098;
    color: white;
    background-color: #004098;
    width: 260px;
    height: 60px;
    font-weight: 550;
}

.sobrenospagecontent .sobrenospagecontent-phone .map {
    margin-top: 40px;
    margin-bottom: 40px;
    
}

@media (max-width: 1230px)
{
    .sobrenospagecontent .desktop {
        display: none;
    }
    .sobrenospagecontent .phone {
        display: flex;
    }

}